import React, { FC } from 'react';

import { EndOfContractOption, useTrEndOfContractOption } from '@hofy/api-shared';
import { MultilineFormDropdown } from '@hofy/common';

import { useCoreI18n } from '../../../i18n/useCoreI18n';

interface DropdownItem {
    title: string;
    value: EndOfContractOption | null;
    subtitle?: string;
    disabled?: boolean;
}

interface ContractEndOptionDropdownProps {
    defaultEoc: EndOfContractOption | null;
    contractEoc: EndOfContractOption | null;
    hasDifferentOptions?: boolean;

    setContractEoc(contractEoc: EndOfContractOption | null): void;
}

export const ContractEndOptionDropdown: FC<ContractEndOptionDropdownProps> = ({
    defaultEoc,
    contractEoc,
    hasDifferentOptions,
    setContractEoc,
}) => {
    const { trEoC, trEocExplanation } = useTrEndOfContractOption();
    const { tr } = useCoreI18n();
    const items: DropdownItem[] = [
        {
            title: trEoC(EndOfContractOption.Rollover),
            subtitle: trEocExplanation(EndOfContractOption.Rollover),
            value: EndOfContractOption.Rollover,
        },
        {
            title: trEoC(EndOfContractOption.EndContractAndCollect),
            subtitle: trEocExplanation(EndOfContractOption.EndContractAndCollect),
            value: EndOfContractOption.EndContractAndCollect,
        },
        {
            title: trEoC(EndOfContractOption.EndContractAndPurchase),
            subtitle: trEocExplanation(EndOfContractOption.EndContractAndPurchase),
            value: EndOfContractOption.EndContractAndPurchase,
        },
    ];

    if (defaultEoc) {
        items.unshift({
            title: tr('end-of-contract-option-dropdown.default-label', {
                currentOrgDefault: trEoC(defaultEoc),
            }),
            subtitle: trEocExplanation(defaultEoc),
            value: null,
        });
    }

    if (hasDifferentOptions) {
        items.unshift({
            title: tr('end-of-contract-option-dropdown.different-options-label'),
            value: null,
            disabled: true,
        });
    }

    return (
        <MultilineFormDropdown
            items={items}
            maxWidth={600}
            compareValue={v => v.value === contractEoc}
            onChange={setContractEoc}
            label={tr('end-of-contract-option-dropdown.label')}
        />
    );
};
