import { getEnumValues } from '@hofy/global';

export enum EndOfContractOption {
    Rollover = 'rollover',
    EndContractAndCollect = 'end_contract_and_collect',
    EndContractAndPurchase = 'end_contract_and_purchase',
    NotSpecified = 'not_specified',
}

export const allEndOfContractOptions = getEnumValues<EndOfContractOption>(EndOfContractOption);
export const endingEndOfContractOptions = [
    EndOfContractOption.EndContractAndCollect,
    EndOfContractOption.EndContractAndPurchase,
];
