export const endOfContractOptionI18n = {
    'end-of-contract-option.null': 'Default Setting',
    'end-of-contract-option-explanation.null': 'Extended',
    'end-of-contract-option.rollover': 'Extend',
    'end-of-contract-option-explanation.rollover':
        'Pay the 36 monthly contract rental price on a monthly basis',
    'end-of-contract-option.end-contract-and-collect': 'Return to Hofy',
    'end-of-contract-option-explanation.end-contract-and-collect':
        'No payment required (subject to item condition)',
    'end-of-contract-option.end-contract-and-purchase': 'Purchase',
    'end-of-contract-option-explanation.end-contract-and-purchase':
        'Pay the purchase fee for your organisation to own the item',
    'end-of-contract-option.not-specified': 'Not specified',

    'end-of-contract-option-dropdown.label': 'Contract option',
    'end-of-contract-option-dropdown.default-label': 'Default setting ({currentOrgDefault})',

    'end-of-contract-option-dropdown.different-options-label': 'Using advanced settings',
};
