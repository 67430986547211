import { Country, DateString, Price } from '@hofy/global';

import { ContractType } from './ContractType';
import { CustomerOwnedStoreAndReuseLocation } from './CustomerOwnedStoreAndReuseLocation';
import { endingEndOfContractOptions, EndOfContractOption } from './EndOfContractOption';
import { PaymentType } from './PaymentType';
import { PurchaseContractStatus } from './PurchaseContractStatus';
import { RentalContractStatus } from './RentalContractStatus';
import { RentalContractType } from './RentalContractType';
import { RentalTerm } from './RentalTerm';
import { StoreAndReuseDto } from './StoreAndReuseDto';

export type ContractUnionDto = ContractDtoWithRental | ContractDtoWithPurchase | ContractDtoWithManagement;

interface ContractDto {
    id: number;
    type: ContractType;
    country: Country;
    rentalContract: RentalContractDto | null;
    purchaseContract: PurchaseContractDto | null;
    managementContract: ManagementContractDto | null;
    publicId: string;
    paymentType: PaymentType;
}

export interface ContractDtoWithRental extends ContractDto {
    type: ContractType.Rental;
    rentalContract: RentalContractDto;
    purchaseContract: null;
}

export interface ContractDtoWithPurchase extends ContractDto {
    type: ContractType.Purchase;
    purchaseContract: PurchaseContractDto;
    rentalContract: null;
}

export interface ContractDtoWithManagement extends ContractDto {
    type: ContractType.Management;

    managementContract: ManagementContractDto;
}

export interface ManagementContractDto {
    id: number;
    isMain: boolean;
    storageMonthlyFee: Price;
    activationFee: Price;
    collectionLocation: CustomerOwnedStoreAndReuseLocation | null;
    storeAndReuse: StoreAndReuseDto;
}

export interface PurchaseContractDto {
    id: number;
    status: PurchaseContractStatus;
    pendingOn: DateString;
    purchasedOn: DateString | null;
    cancelledOn: DateString | null;
    price: Price;
}

export interface RentalContractDto {
    id: number;
    isMain: boolean;
    status: RentalContractStatus;
    type: RentalContractType;
    rentalTerm: RentalTerm;
    rentalLength: number;
    pendingOn: DateString;
    activeOn: DateString | null;
    endedOn: DateString | null;
    cancelledOn: DateString | null;
    price: Price;
    storeAndReuse: StoreAndReuseDto | null;
    executedEndOfContractOption: EndOfContractOption | null;
    estimatedEndOfContract: DateString | null;
}

export const getAssignmentStoreAndReuse = (contract: ContractUnionDto | null) => {
    if (!contract) {
        return null;
    }
    if (
        contract.type === ContractType.Purchase ||
        contract.type === ContractType.Management ||
        contract.managementContract
    ) {
        return contract.managementContract?.storeAndReuse ?? null;
    }
    return contract.rentalContract?.storeAndReuse ?? null;
};

export const getContractStatus = (contract: {
    type: ContractType;
    rentalContract: {
        status: RentalContractStatus;
    } | null;
    purchaseContract: {
        status: PurchaseContractStatus;
    } | null;
}): RentalContractStatus | PurchaseContractStatus | null => {
    switch (contract.type) {
        case ContractType.Rental:
            return contract.rentalContract?.status!;
        case ContractType.Purchase:
            return contract.purchaseContract?.status!;
    }
    return null;
};

export const isEndingRental = (contract: {
    rentalContract: {
        executedEndOfContractOption: EndOfContractOption | null;
    } | null;
}) => {
    const mainRentalContract = contract.rentalContract;
    if (!mainRentalContract?.executedEndOfContractOption) {
        return false;
    }
    return endingEndOfContractOptions.includes(mainRentalContract.executedEndOfContractOption);
};
