import { useMutation, useQueryClient } from '@tanstack/react-query';

import { isValidHttpsURL, useForm, useToast, validator } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationFeaturesPayload } from '../../services/organizations/types/UpdateOrganizationFeaturesPayload';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useAdminUpdateOrganizationFeatures = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationFeaturesPayload) =>
            adminOrganizationsService.updateOrganizationFeatures(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                message: `Organization ${organization.name} features successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationFeaturesPayload>({
        initial: {
            welcomePacksEnabled: organization.welcomePacksEnabled,
            serviceDeskEnabled: organization.serviceDeskEnabled,
            deviceConfigRequired: organization.deviceConfigRequired,
            dseEnabled: organization.dseEnabled,
            hostAgentEnabled: organization.hostAgentEnabled,
            storeAndReuseManagedByHofy: organization.storeAndReuseManagedByHofy,
            collectionSurveySkipDeviceReset: organization.collectionSurveySkipDeviceReset,
            deviceResetInstructionsLink: organization.deviceResetInstructionsLink,
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationFeaturesPayload>({
            deviceResetInstructionsLink: [
                isValidHttpsURL(
                    'Custom device reset instructions link must be a valid URL that begins with https://',
                ),
            ],
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
