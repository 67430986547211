import { noop } from 'lodash';
import React, { ReactNode, useMemo } from 'react';

import { Color } from '@hofy/theme';
import { Box, BoxProps, Paragraph3, SectionTitle3, TestKeyAware } from '@hofy/ui';

import { FormDropdown } from './FormDropdown';

interface MultilineItem<T> extends TestKeyAware {
    title: string | ReactNode;
    subtitle?: string | ReactNode;
    disabled?: boolean;
    hidden?: boolean;
    value?: T;
}

/**
 * @param {MultilineItem}  compareValue - a predicate to find which value should be select. Similar to 'value' property of @FormDropdown
 */
interface MultilineFormDropdownProps<T> extends TestKeyAware, BoxProps {
    items: MultilineItem<T>[];
    compareValue(i: MultilineItem<T>): boolean;
    onChange?(v?: T): void;
    disabled?: boolean;
    label?: string;
}

/** @deprecated use `LabeledSelect` or `FormSelect` from `@hofy/ui` with custom `toLabel` prop */
export const MultilineFormDropdown = <T extends any>({
    items,
    testKey,
    compareValue,
    onChange = noop,
    disabled,
    label,
    ...boxProps
}: MultilineFormDropdownProps<T>) => {
    const selectedValue = items.find(compareValue);
    const getColor = (i: MultilineItem<T>) => (i?.disabled || disabled ? Color.Neutral300 : Color.Neutral700);
    const filteredItems = useMemo(() => items.filter(i => !i.hidden), [items]);
    return (
        <FormDropdown
            {...boxProps}
            testKey={testKey}
            label={label}
            items={filteredItems}
            value={selectedValue}
            labelFormatter={i => <Paragraph3 color={getColor(i)}>{i.title}</Paragraph3>}
            labelListFormatter={i => (
                <Box marginVertical={5}>
                    <SectionTitle3 color={getColor(i)}>{i.title}</SectionTitle3>
                    <Paragraph3 color={getColor(i)}>{i.subtitle}</Paragraph3>
                </Box>
            )}
            onChange={i => !i?.disabled && onChange(i?.value)}
            disabled={disabled}
        />
    );
};
