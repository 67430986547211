import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEmpty } from 'lodash';

import { BillingEntityDto, getDefaultBillingEntity } from '@hofy/api-admin';
import {
    AcquisitionType,
    CategoryContractSettingsConfigDto,
    OrganizationStatus,
    PaymentSchema,
} from '@hofy/api-shared';
import { isValidRegCompanyNumber } from '@hofy/global';
import { isPriceGreaterThan, isRequired, useForm, useToast, validator } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationFinancialSettingsPayload } from '../../services/organizations/types/UpdateOrganizationFinancialSettingsPayload';
import { organizationsCacheKey } from './organizationsCacheKey';

const getPaymentSchemas = (contracts: CategoryContractSettingsConfigDto[]): PaymentSchema[] => {
    return contracts
        .filter(v => v.acquisitionType === AcquisitionType.Rental)
        .map(value => value.paymentSchema!);
};

export const useUpdateOrganizationFinancialSettings = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationFinancialSettingsPayload) =>
            adminOrganizationsService.updateOrganizationFinancialSettings(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                message: `Organization ${organization!.name} financial settings successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const isOrganizationActive = organization?.status === OrganizationStatus.Active;

    const form = useForm<UpdateOrganizationFinancialSettingsPayload>({
        initial: {
            unbundlingEnabled: organization.unbundlingEnabled,
            creditLimit: organization.crediting.creditLimit,
            sassFee: organization.pricing.sassFee,
            laptopConfigFee: organization.pricing.laptopConfigFee,
            mdmFee: organization.pricing.mdmFee,
            allowedBillingFrequencies: organization.allowedBillingFrequencies,
            allowedRentalTerms: organization.allowedRentalTerms,
            billingEntities: organization.billingEntities,
            platformTierConfig: organization.platformTierConfig,
            usedPaymentSchemas: getPaymentSchemas(organization.defaultContractSettings),
            storefrontFee: organization.storefrontFee,
            allowedAcquisitionTypes: organization.allowedAcquisitionTypes,
            paymentType: organization.defaultPaymentType,
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationFinancialSettingsPayload>({
            creditLimit: [isPriceGreaterThan('-1', 'Credit limit cannot be negative.')],
            sassFee: [
                isRequired('SAAS fee is required'),
                isPriceGreaterThan('-1', 'SAAS fee cannot be negative.'),
            ],
            laptopConfigFee: [isPriceGreaterThan('-1', 'Configuration fee cannot be negative.')],
            mdmFee: [isPriceGreaterThan('-1', 'MDM fee cannot be negative.')],
            storefrontFee: [isRequired('Organization storefront fee is required')],
            billingEntities: [
                v =>
                    isOrganizationActive && !getDefaultBillingEntity(v)
                        ? 'No default billing entity set'
                        : undefined,
                v =>
                    isOrganizationActive && !getDefaultBillingEntity(v)?.billingAddress?.country
                        ? 'Billing entity does not have country set'
                        : undefined,
                v =>
                    isOrganizationActive && !validateDefaultBillingEntity(v)
                        ? 'Billing entity has wrong address or company number'
                        : undefined,
            ],
        }),
    });

    return {
        form,
        isLoadingMutation: mutation.isPending,
    };
};

const validateDefaultBillingEntity = (billingEntities: BillingEntityDto[]): boolean => {
    const defaultBillingEntity = getDefaultBillingEntity(billingEntities);
    if (!defaultBillingEntity?.billingAddress) {
        return false;
    }
    return !(
        isEmpty(defaultBillingEntity.billingAddress.line1) ||
        isEmpty(defaultBillingEntity.billingAddress.postCode) ||
        isEmpty(defaultBillingEntity.billingAddress.city) ||
        !isValidRegCompanyNumber(
            defaultBillingEntity.registeredCompanyNumber,
            defaultBillingEntity.billingAddress.country,
        )
    );
};
