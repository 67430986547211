import { useSharedApiI18n } from '../i18n/useSharedApiI18n';
import { EndOfContractOption } from '../types/EndOfContractOption';

export const useTrEndOfContractOption = () => {
    const { trEnum } = useSharedApiI18n();

    const trEoC = trEnum<EndOfContractOption>(EndOfContractOption, 'end-of-contract-option');
    const trEocExplanation = trEnum<EndOfContractOption>(
        EndOfContractOption,
        'end-of-contract-option-explanation',
    );

    return {
        trEoC,
        trEocExplanation,
    };
};
