import React, { FC } from 'react';

import { allOperatingSystems, useTrOperatingSystem } from '@hofy/api-shared';
import { MultiSelectDropdown } from '@hofy/common';
import {
    AsyncButton,
    FormCheckbox,
    FormContainer,
    FormInput,
    FormSection,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useAdminUpdateOrganizationFeatures } from '../../../store/organizations/useAdminUpdateOrganizationFeatures';

interface FeaturesUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const FeaturesUpdateModal: FC<FeaturesUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useAdminUpdateOrganizationFeatures(organization, onClose);
    const { tr } = useAdminI18n();

    const trOperatingSystem = useTrOperatingSystem();

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader
                title={tr('organization-page.features-update.title')}
                subtitle={tr('organization-page.features-update.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <FormSection label={tr('organization-page.features-update.modal.features-section')}>
                        <FormCheckbox
                            label={tr('organization-page.features-update.modal.welcome-packs')}
                            api={form.fields.welcomePacksEnabled}
                        />
                        <FormCheckbox
                            label={tr('organization-page.features-update.modal.service-desk')}
                            api={form.fields.serviceDeskEnabled}
                        />
                        <FormCheckbox
                            label={tr('organization-page.features-update.modal.device-wiping')}
                            api={form.fields.collectionSurveySkipDeviceReset}
                        />

                        <FormCheckbox
                            label={tr('organization-page.features-update.modal.store-and-reuse')}
                            api={form.fields.storeAndReuseManagedByHofy}
                        />
                        <FormCheckbox
                            label={tr('organization-page.features-update.modal.dse')}
                            api={form.fields.dseEnabled}
                        />
                        <FormCheckbox
                            label={tr('organization-page.features-update.modal.host-agent')}
                            api={form.fields.hostAgentEnabled}
                        />
                        <MultiSelectDropdown
                            label={tr('organization-page.features-update.modal.device-config')}
                            items={allOperatingSystems}
                            value={form.values.deviceConfigRequired}
                            onChange={deviceConfigRequired =>
                                form.setValues({
                                    deviceConfigRequired,
                                })
                            }
                            labelFormatter={trOperatingSystem}
                            clearable
                        />
                        <FormInput
                            label={tr('organization-page.features-update.modal.reset-link')}
                            helperText={tr('organization-page.features-update.modal.reset-link.helper-text')}
                            nullable
                            api={form.fields.deviceResetInstructionsLink}
                        />
                    </FormSection>
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton
                    label={tr('organization-page.features-update.modal.cancel')}
                    onClick={onClose}
                />
                <AsyncButton
                    label={tr('organization-page.features-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
