import { useMutation, useQueryClient } from '@tanstack/react-query';

import { EndOfContractSettings, organizationService, UpdateEndOfContractPayload } from '@hofy/api-admin';
import { ParentProductCategory, ProductCategory } from '@hofy/api-shared';
import { useForm, useToast } from '@hofy/ui';

import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { organizationsCacheKey } from './organizationsCacheKey';
import { EndOfContractSettingsFormData } from './types/EndOfContractSettingsFormData';

export const useUpdateOrganizationEndOfContract = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateEndOfContractPayload) =>
            organizationService.updateEndOfContract(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey] });
            showToast({
                message: `Organization ${organization!.name} end of contract settings successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const formDataToPayload = (formData: EndOfContractSettingsFormData): UpdateEndOfContractPayload => {
        const endOfContractSettings = [
            {
                endOfContractOption: formData.laptopEoC,
                productCategory: ProductCategory.Laptop,
            },
            {
                endOfContractOption: formData.desktopEoC,
                productCategory: ProductCategory.Desktop,
            },
            {
                endOfContractOption: formData.tabletEoC,
                productCategory: ProductCategory.Tablet,
            },
            {
                endOfContractOption: formData.mobilePhoneEoC,
                productCategory: ProductCategory.MobileDevice,
            },
            {
                endOfContractOption: formData.itPeripheralsEoC,
                productParentCategory: ParentProductCategory.ITPeripherals,
            },
            {
                endOfContractOption: formData.itAccessoriesEoC,
                productParentCategory: ParentProductCategory.ITAccessories,
            },
            {
                endOfContractOption: formData.furnitureEoC,
                productParentCategory: ParentProductCategory.Furniture,
            },
            {
                endOfContractOption: formData.furnitureAccessoriesEoC,
                productParentCategory: ParentProductCategory.FurnitureAccessories,
            },
        ].filter(eoc => eoc.endOfContractOption) as EndOfContractSettings[];

        return { endOfContractSettings };
    };

    const {
        endOfContractSettingsByCategory: settingsByCategory,
        endOfContractSettingsByParentCategory: settingsByParentCategory,
    } = organization;

    const form = useForm<EndOfContractSettingsFormData>({
        initial: {
            laptopEoC: settingsByCategory[ProductCategory.Laptop] || null,
            desktopEoC: settingsByCategory[ProductCategory.Desktop] || null,
            tabletEoC: settingsByCategory[ProductCategory.Tablet] || null,
            mobilePhoneEoC: settingsByCategory[ProductCategory.MobileDevice] || null,
            itPeripheralsEoC: settingsByParentCategory[ParentProductCategory.ITPeripherals] || null,
            itAccessoriesEoC: settingsByParentCategory[ParentProductCategory.ITAccessories] || null,
            furnitureEoC: settingsByParentCategory[ParentProductCategory.Furniture] || null,
            furnitureAccessoriesEoC:
                settingsByParentCategory[ParentProductCategory.FurnitureAccessories] || null,
        },
        onSubmit: values => mutation.mutate(formDataToPayload(values)),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
