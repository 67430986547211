import { isEmpty, isEqual } from 'lodash';
import React, { FC } from 'react';

import {
    allPaymentSchemasWithoutDeposit,
    formatAdminName,
    getAvailableRentalTerms,
    ParentProductCategory,
    ProductCategory,
    useTrAcquisitionType,
    useTrEndOfContractOption,
    useTrOperatingSystem,
    useTrOrganizationSize,
    useTrParentProductCategory,
    useTrPaymentSchema,
    useTrPaymentType,
    useTrProductCategory,
    useTrRentalTerm,
} from '@hofy/api-shared';
import { StatusFlag } from '@hofy/common';
import { Price } from '@hofy/global';
import { usePrice } from '@hofy/hooks';
import {
    Box,
    FormGridRow,
    FormSection,
    InfoTooltipIcon,
    Labeled,
    LabeledText,
    SvgIcon,
    TooltipIcon,
} from '@hofy/ui';

import { LinkType, ZendeskSection } from '../../../components/design/zendesk/ZendeskSection';
import { AuditSection } from '../../../components/domain/auditingInline/AuditSection';
import { OrganizationStatusChip } from '../../../components/domain/organizations/OrganizationStatusChip';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useTrOrganizationType } from '../../../store/organizations/useTrOrganizationType';
import { PlatformTierSection } from './PlatformTierSection';

interface DetailsTabProps {
    organization: OrganizationDetailsDto;
}

export const DetailsTab: FC<DetailsTabProps> = ({ organization }) => {
    const trPaymentSchema = useTrPaymentSchema();
    const trRentalTerm = useTrRentalTerm();
    const trOperatingSystem = useTrOperatingSystem();
    const trAcquisitionType = useTrAcquisitionType();
    const trOrganizationType = useTrOrganizationType();
    const { trEoC } = useTrEndOfContractOption();
    const trProductCategory = useTrProductCategory();
    const trParentProductCategory = useTrParentProductCategory();
    const trPaymentType = useTrPaymentType();
    const trOrganizationSize = useTrOrganizationSize();

    const { formatPrice } = usePrice();

    const nonDefaultValueTooltip = (value: Price, defaultValue: Price, name: string) => {
        if (!isEqual(value, defaultValue)) {
            return (
                <TooltipIcon
                    placement='top-start'
                    icon={SvgIcon.Asterisk}
                    iconSize={12}
                    body={`This is a custom ${name}. Default is ${formatPrice(defaultValue)}`}
                />
            );
        } else {
            return undefined;
        }
    };

    const deviceConfigurationLabelSuffix = !isEmpty(organization.deviceConfigRequired)
        ? ` (${organization.deviceConfigRequired.map(os => trOperatingSystem(os)).join(', ')})`
        : '';

    const successAdminUserName = organization.successAdminUser
        ? formatAdminName(organization.successAdminUser)
        : '';
    const salesAdminUserName = organization.salesAdminUser
        ? formatAdminName(organization.salesAdminUser)
        : '';

    const allRentalTerms = getAvailableRentalTerms(organization.unbundlingEnabled);

    const eocCategoryFormatter = (category: ProductCategory) => {
        const eoc = organization.endOfContractSettingsByCategory[category];
        return eoc ? trEoC(eoc) : '--';
    };

    const eocParentCategoryFormatter = (parentProductCategory: ParentProductCategory) => {
        const eoc = organization.endOfContractSettingsByParentCategory[parentProductCategory];
        return eoc ? trEoC(eoc) : '--';
    };

    const eocSectionTitle = (
        <Box row inline gap={4}>
            End of Contract default settings
            <InfoTooltipIcon body='Settings can be overwritten on individual contracts' />
        </Box>
    );

    return (
        <Box flex={1} overflow='auto' fullHeight row alignItems='flex-start' paddingHorizontal={40}>
            <Box flex={1} marginVertical={30} marginRight={20}>
                <FormSection label='Organisation'>
                    <Box row>
                        <LabeledText flex={1} label='Name' content={organization.name} />
                        <LabeledText flex={1} label='Id' content={organization.id} />
                    </Box>
                    <Box row alignItems='flex-start'>
                        <LabeledText flex={1} label='Uuid' content={organization.uuid} />
                        <LabeledText flex={1} label='Currency' content={organization.currency} />
                    </Box>
                    <Box row>
                        <LabeledText flex={1} label='Sales representative' content={salesAdminUserName} />
                        <Labeled
                            flex={1}
                            label='Status'
                            content={<OrganizationStatusChip status={organization.status} />}
                        />
                    </Box>
                    <Box row>
                        <LabeledText flex={1} label='Success representative' content={successAdminUserName} />
                        <LabeledText
                            flex={1}
                            label='Model'
                            content={organization.unbundlingEnabled ? 'Unbundled' : 'Bundled'}
                        />
                    </Box>
                    <Box row>
                        <LabeledText
                            flex={1}
                            label='Allowed Acquisition Types'
                            content={organization.allowedAcquisitionTypes
                                .map(type => trAcquisitionType(type))
                                .join(', ')}
                        />
                        <LabeledText flex={1} label='Type' content={trOrganizationType(organization.type)} />
                    </Box>
                    <Box row>
                        <LabeledText
                            flex={1}
                            label='Parent partner organization'
                            content={organization.partnerName || '--'}
                        />
                        <LabeledText
                            flex={1}
                            label='Organization size'
                            content={organization.size && trOrganizationSize(organization.size)}
                        />
                    </Box>
                    <Box row>
                        <LabeledText
                            flex={1}
                            label='Default payment type'
                            content={trPaymentType(organization.defaultPaymentType)}
                        />
                    </Box>
                </FormSection>
                <FormSection marginTop={30} label='Allowed billing frequency'>
                    <FormGridRow columns={4}>
                        {allPaymentSchemasWithoutDeposit.map(paymentSchema => (
                            <StatusFlag
                                key={paymentSchema}
                                label={trPaymentSchema(paymentSchema)}
                                active={organization.allowedBillingFrequencies.includes(paymentSchema)}
                            />
                        ))}
                    </FormGridRow>
                </FormSection>
                <FormSection marginTop={30} label='Allowed rental terms'>
                    <FormGridRow columns={4}>
                        {allRentalTerms.map(term => (
                            <StatusFlag
                                key={term}
                                label={trRentalTerm(term)}
                                active={organization.allowedRentalTerms.includes(term)}
                            />
                        ))}
                    </FormGridRow>
                </FormSection>
                <FormSection marginTop={30} label={eocSectionTitle}>
                    <FormGridRow columns={4}>
                        {[
                            ProductCategory.Laptop,
                            ProductCategory.Desktop,
                            ProductCategory.Tablet,
                            ProductCategory.MobileDevice,
                        ].map(category => (
                            <Box key={category}>
                                <LabeledText
                                    flex={1}
                                    label={trProductCategory(category)}
                                    content={eocCategoryFormatter(category)}
                                />
                            </Box>
                        ))}
                    </FormGridRow>
                    <FormGridRow columns={4}>
                        {[
                            ParentProductCategory.ITPeripherals,
                            ParentProductCategory.ITAccessories,
                            ParentProductCategory.Furniture,
                            ParentProductCategory.FurnitureAccessories,
                        ].map(category => (
                            <Box key={category}>
                                <LabeledText
                                    flex={1}
                                    label={trParentProductCategory(category)}
                                    content={eocParentCategoryFormatter(category)}
                                />
                            </Box>
                        ))}
                    </FormGridRow>
                </FormSection>
                <FormSection marginTop={30} label='Features'>
                    <StatusFlag label='Welcome packs enabled' active={organization.welcomePacksEnabled} />
                    <StatusFlag
                        label='Skip device wiping prompt in collection survey'
                        active={organization.collectionSurveySkipDeviceReset}
                    />
                    <StatusFlag label='Service desk' active={organization.serviceDeskEnabled} />
                    <StatusFlag label='MDM' active={organization.mdmEnabled} />
                    <StatusFlag label='Data erasure' active={organization.dataErasureEnabled} />
                    <StatusFlag
                        label={`Device configuration${deviceConfigurationLabelSuffix}`}
                        active={!isEmpty(organization.deviceConfigRequired)}
                    />
                    <StatusFlag
                        label='Store and reuse managed by hofy'
                        active={organization.storeAndReuseManagedByHofy}
                    />
                    <StatusFlag label='DSE' active={organization.dseEnabled} />
                </FormSection>
            </Box>
            <Box flex={1} marginVertical={30} marginLeft={20} column gap={30}>
                <FormSection label='Pricing'>
                    <Box row>
                        <LabeledText
                            flex={1}
                            label='MDM fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.mdmFee,
                                organization.defaultPricing.mdmFee,
                                'MDM fee',
                            )}
                            content={formatPrice(organization.pricing.mdmFee)}
                        />
                        <LabeledText
                            flex={1}
                            label='Config fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.laptopConfigFee,
                                organization.defaultPricing.laptopConfigFee,
                                'Config fee',
                            )}
                            content={formatPrice(organization.pricing.laptopConfigFee)}
                        />
                        <LabeledText
                            flex={1}
                            label='Data erasure fee'
                            labelSlot={nonDefaultValueTooltip(
                                organization.pricing.dataErasureFee,
                                organization.defaultPricing.dataErasureFee,
                                'Data erasure fee',
                            )}
                            content={formatPrice(organization.pricing.dataErasureFee)}
                        />
                    </Box>
                    {!!organization.mdmCount && (
                        <Box row>
                            <LabeledText flex={1} label='Mdm device count' content={organization.mdmCount} />
                        </Box>
                    )}
                </FormSection>
                <FormSection label='Crediting'>
                    <Box row>
                        <LabeledText
                            flex={1}
                            label='Credit limit (Monthly)'
                            content={formatPrice(organization.crediting.creditLimit)}
                        />
                    </Box>
                    <LabeledText
                        flex={1}
                        label='Credit used'
                        content={formatPrice(organization.crediting.creditUsed)}
                    />
                </FormSection>
                <ZendeskSection linkType={LinkType.OrganizationPage} zendesk={organization.zendesk} />
                <AuditSection model={organization} />
                <PlatformTierSection
                    organization={organization}
                    priceTooltip={nonDefaultValueTooltip(
                        organization.pricing.sassFee,
                        organization.defaultPricing.sassFee,
                        'Platform tier price',
                    )}
                />
            </Box>
        </Box>
    );
};
