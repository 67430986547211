import React, { FC } from 'react';

import { EndOfContractOption } from '@hofy/api-shared';
import { ContractEndOptionDropdown } from '@hofy/core';
import { Button, FormModal, ModalContent, ModalFooter, ModalHeader, SubmitButton } from '@hofy/ui';

import { useUpdateEndOfContractOption } from '../../../store/contracts/useUpdateEndOfContractOption';

interface UpdateContractEndOptionModalProps {
    contractID: number;
    contractEoC: EndOfContractOption | null;
    orgEoC: EndOfContractOption | null;
    onClose(): void;
}

export const UpdateContractEndOptionModal: FC<UpdateContractEndOptionModalProps> = ({
    contractID,
    contractEoC,
    orgEoC,
    onClose,
}) => {
    const { form, isLoading, isError } = useUpdateEndOfContractOption(contractID, contractEoC, onClose);

    return (
        <FormModal
            width={600}
            onSubmit={form.submit}
            onClose={form.discard}
            isLoading={isLoading}
            isError={isError}
        >
            <ModalHeader title='End of contract options' />
            <ModalContent>
                <ContractEndOptionDropdown
                    defaultEoc={orgEoC}
                    contractEoc={form.values.endOfContractOption}
                    setContractEoc={endOfContractOption => form.setValues({ endOfContractOption })}
                />
            </ModalContent>
            <ModalFooter>
                <Button type='secondary' label='Cancel' onClick={form.discard} />
                <SubmitButton label='Update' />
            </ModalFooter>
        </FormModal>
    );
};
