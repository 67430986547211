import React, { FC } from 'react';

import { allEndOfContractOptions, useTrEndOfContractOption } from '@hofy/api-shared';
import {
    AsyncButton,
    FormContainer,
    FormSection,
    FormSelect,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useUpdateOrganizationEndOfContract } from '../../../store/organizations/useUpdateOrganizationEndOfContract';

interface EndOfContractUpdateModalProps {
    onClose(): void;
    organization: OrganizationDetailsDto;
}

export const EndOfContractUpdateModal: FC<EndOfContractUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useUpdateOrganizationEndOfContract(organization, onClose);
    const { trEoC } = useTrEndOfContractOption();

    return (
        <Modal onClose={onClose} width={600}>
            <ModalHeader title='Update end of contract settings' />
            <ModalContent>
                <FormContainer>
                    <FormSection label='Devices'>
                        <FormSelect
                            label='Laptops'
                            options={allEndOfContractOptions}
                            api={form.fields.laptopEoC}
                            toText={trEoC}
                        />
                        <FormSelect
                            label='Desktops'
                            options={allEndOfContractOptions}
                            api={form.fields.desktopEoC}
                            toText={trEoC}
                        />
                        <FormSelect
                            label='Tablets'
                            options={allEndOfContractOptions}
                            api={form.fields.tabletEoC}
                            toText={trEoC}
                        />
                        <FormSelect
                            label='Mobile Phones'
                            options={allEndOfContractOptions}
                            api={form.fields.mobilePhoneEoC}
                            toText={trEoC}
                        />
                    </FormSection>

                    <FormSection label='Accessories'>
                        <FormSelect
                            label='IT Peripherals'
                            options={allEndOfContractOptions}
                            api={form.fields.itPeripheralsEoC}
                            toText={trEoC}
                        />
                        <FormSelect
                            label='IT Accessories'
                            options={allEndOfContractOptions}
                            api={form.fields.itAccessoriesEoC}
                            toText={trEoC}
                        />
                        <FormSelect
                            label='Furniture'
                            options={allEndOfContractOptions}
                            api={form.fields.furnitureEoC}
                            toText={trEoC}
                        />
                        <FormSelect
                            label='Furniture Accessories'
                            options={allEndOfContractOptions}
                            api={form.fields.furnitureAccessoriesEoC}
                            toText={trEoC}
                        />
                    </FormSection>
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label='Cancel' onClick={onClose} />
                <AsyncButton label='Save' disableCheck onClick={form.submit} isLoading={isLoading} />
            </ModalFooter>
        </Modal>
    );
};
