import { activityLogI18n } from '@hofy/activity-log';
import { addressI18n } from '@hofy/address';
import { adminApiI18n } from '@hofy/api-admin';
import { sharedApiI18n } from '@hofy/api-shared';
import { authI18n } from '@hofy/auth';
import { coreI18n } from '@hofy/core';
import { globalI18n } from '@hofy/global';
import { productI18n } from '@hofy/product';

import { accountKeyI18n } from './accountKeyI18n';
import { adminAccountingI18n } from './adminAccountingI18n';
import { adminAssignmentSlideoutTabI18n } from './adminAssignmentSlideoutTabI18n';
import { adminBillingEntityPageI18n } from './adminBillingEntityPageI18n';
import { adminBillingEntityTabI18n } from './adminBillingEntityTabI18n';
import { adminContractFilterI18n } from './adminContractFilterI18n';
import { adminIntegrationErrorsFilterI18n } from './adminIntegrationErrorsFilterI18n';
import { adminInventoryI18n } from './adminInventoryI18n';
import { adminInvoicingEntityFilterI18n } from './adminInvoicingEntityFilterI18n';
import { adminInvoicingTabI18n } from './adminInvoicingTabI18n';
import { adminIssueFilterI18n } from './adminIssueFilterI18n';
import { adminOrderFilterI18n, adminOrderSortOptionI18n } from './adminOrderFilterI18n';
import { adminOrderSlideoutTabI18n } from './adminOrderSlideoutTabI18n';
import { adminSettingsTabI18n } from './adminSettingsTabI18n';
import { adminShipmentFilterI18n } from './adminShipmentFilterI18n';
import { adminShipmentPagei18n } from './adminShipmentPagei18n';
import { adminShipmentSlideoutCollectionSurveyStatusI18n } from './adminShipmentSlideoutCollectionSurveyStatusI18n';
import { adminShipmentSlideoutTabI18n } from './adminShipmentSlideoutTabI18n';
import { addLoanerModalI18n } from './assignments/addLoanerModalI18n';
import { appendAssignmenttoShipmentModalI18n } from './assignments/appendAssignmentToShipmentModalI18n';
import { assignmentConfigurationConfirmationModalI18n } from './assignments/assignmentConfigurationConfirmationModalI18n';
import { assignmentConfigurationStatusI18n } from './assignments/assignmentConfigurationStatusI18n';
import { assignmentDataErasureStatusI18n } from './assignments/assignmentDataErasureStatusI18n';
import { assignmentItemDisposeI18n } from './assignments/assignmentItemDisposeI18n';
import { assignmentPurchaseI18n } from './assignments/assignmentPurchaseI18n';
import { assignmentRevertCompletionModalI18n } from './assignments/assignmentRevertCompletionModalI18n';
import { assignmentShipmentClearTrackingExceptionModalI18n } from './assignments/assignmentShipmentClearTrackingExceptionModalI18n';
import { assignmentShipmentCreateSurveyModalI18n } from './assignments/assignmentShipmentCreateSurveyModalI18n';
import { assignmentShipmentDetailsUpdateModalI18n } from './assignments/assignmentShipmentDetailsUpdateModalI18n';
import { assignmentShipmentEnableTrackingModalI18n } from './assignments/assignmentShipmentEnableTrackingModalI18n';
import { assignmentShipmentIssueModalI18n } from './assignments/assignmentShipmentIssueModalI18n';
import { assignmentsPageTabI18n } from './assignments/assignmentsPageTabI18n';
import { assignmentStolenModalI18n } from './assignments/assignmentStolenI18n';
import { cancelCollectionModalI18n } from './assignments/cancelCollectionModalI18n';
import { cancelOrderModalI18n } from './assignments/cancelOrderModalI18n';
import { cancelStoreAndReuseCollectionModalI18n } from './assignments/cancelStoreAndReuseCollectionModalI18n';
import { cancelStoreAndReuseDeliveryModalI18n } from './assignments/cancelStoreAndReuseDeliveryModalI18n';
import { collectAssignmentModalI18n } from './assignments/collectAssignmentModalI18n';
import { convertLoanerToReplacementModalI18n } from './assignments/convertLoanerToReplacementModalI18n';
import { editShipmentSlideoutI18n } from './assignments/editShipmentSlideoutI18n';
import { enableStoreAndReuseDeliveryI18n } from './assignments/enableStoreAndReuseDeliveryI18n';
import { orderTypeI18n } from './assignments/orderTypeI18n';
import { putAssignmentOnHoldModalI18n } from './assignments/putAssignmentOnHoldModalI18n';
import { removeAssignmentFromShipmentModalI18n } from './assignments/removeAssignmentFromShipmentModalI18n';
import { removeOnHoldAssignmentModalI18n } from './assignments/removeOnHoldAssignmentModalI18n';
import { replaceAssignmentModalI18n } from './assignments/replaceAssignmentModalI18n';
import { updateCollectAssignmentModalI18n } from './assignments/updateCollectAssignmentModalI18n';
import { updateOtdModalI18n } from './assignments/updateOtdModalI18n';
import { updateOtdReasonI18n } from './assignments/updateOtdReasonI18n';
import { auditLogOperationTypeI18n } from './auditLogOperationTypeI18n';
import { auditTableI18n } from './auditTableI18n';
import { billingEntitiesTabI18n } from './billingEntitiesTabI18n';
import { billingEntityCurrencyUpdateI18n } from './billingEntityCurrencyUpdateI18n';
import { cancelCustomProductModalI18n } from './cancelCustomProductModalI18n';
import { cancelPlanModalI18n } from './cancelPlanModalI18n';
import { cancelSubstituteProductModalI18n } from './cancelSubstituteProductModalI18n';
import { contractsI18n } from './contractI18n';
import { creditContractModalI18n } from './creditContractModalI18n';
import { creditInvoiceEntryModalI18n } from './creditInvoiceEntryModalI18n';
import { dataErasureI18n } from './dataErasureI18n';
import { dataErasureStatusI18n } from './dataErasureStatusI18n';
import { deleteExchangeRateModalI18n } from './deleteExchangeRateModalI18n';
import { deleteFileModalI18n } from './deleteFileModalI18n';
import { deleteInvoiceEntryModalI18n } from './deleteInvoiceEntryModalI18n';
import { deleteOrganizationDiscountModalI18n } from './deleteOrganizationDiscountModalI18n';
import { deleteRejectedPlansModalI18n } from './deleteRejectedPlansModalI18n';
import { deleteTermAndConditionModalI18n } from './deleteTermAndConditionModalI18n';
import { deleteUserPersonalDataModalI18n } from './deleteUserPersonalDataModalI18n';
import { disconnectOrganizationHRISModal } from './disconnectOrganizationHRISModalI18n';
import { enableStoreAndReuseCollectionModalI18n } from './enableStoreAndReuseCollectionModalI18n';
import { exportFieldI18n } from './exportFieldI18n';
import { fixedAssetStatusI18n } from './fixedAssetStatusI18n';
import { fixedAssetTypeI18n } from './fixedAssetTypeI18n';
import { forceRecreatePlanModalI18n } from './forceRecreatePlanModalI18n';
import { hofyWarehouseTypeI18n } from './hofyWarehouseTypeI18n';
import { initialCostSource18n } from './initialCostSource18n';
import { invoiceDetailsTabI18n } from './invoiceDetailsTabI18n';
import { invoiceEntryTypeI18n } from './invoiceEntryTypeI18n';
import { invoicePaymentStatusI18n } from './invoicePaymentStatus18n';
import { itemConditionI18n } from './itemConditionI18n';
import { itemEventStatusI18n } from './itemEventStatusI18n';
import { itemFilterTypeI18n } from './itemFilterTypeI18n';
import { itemGradeI18n } from './itemGradeI18n';
import { itemLocationI18n } from './itemLocationI18n';
import { itemConfirmSerialNumberChangeModalI18n } from './items/itemConfirmSerialNumberChangeModalI18n';
import { itemRemoveOrganizationModalI18n } from './items/itemRemoveOrganizationModalI18n';
import { itemSlideoutTabI18n } from './itemSlideoutTabI18n';
import { jobContextI18n } from './jobContextI18n';
import { markCollectionRequestItemAsFoundI18n } from './markCollectionRequestItemAsFoundI18n';
import { netsuiteSyncStatusI18n } from './netsuiteSyncStatusI18n';
import { organizationCurrencyUpdateI18n } from './organizationCurrencyUpdateI18n';
import { organizationDataErasureUpdateI18n } from './organizationDataErasureSettingsUpdateI18n';
import { organizationDetailsUpdateI18n } from './organizationDetailsUpdateI18n';
import { organizationFeaturesUpdateI18n } from './organizationFeaturesUpdateI18n';
import { organizationFinancialSettingsUpdateI18n } from './organizationFinancialSettingsUpdateI18n';
import { organizationMdmUpdateI18n } from './organizationMdmUpdateI18n';
import { organizationsI18n } from './organizationsl18n';
import { organizationStatusUpdateI18n } from './organizationStatusUpdateI18n';
import { organizationTabI18n } from './organizationTabI18n';
import { organizationTypeI18n } from './organizationTypeI18n';
import { paymentDataStatusI18n } from './paymentDataStatusI18n';
import { paymentMethodI18n } from './paymentMethodI18n';
import { paymentProviderI18n } from './paymentProviderI18n';
import { paymentStatusFilterI18n } from './paymentStatusFilter18n';
import { payoutProviderI18n } from './payoutProviderI18n';
import { platformTierI18n } from './platformTierI18n';
import { productDetailsTabI18n } from './productDetailsTabI18n';
import { productStatusI18n } from './productStatusI18n';
import { purchaseOrderStatusI18n } from './purchaseOrderStatusI18n';
import { purchaseOrderTabI18n } from './purchaseOrderTabI18n';
import { recheckPlansModalI18n } from './recheckPlansModalI18n';
import { recreateInvoiceEntryModalI18n } from './recreateInvoiceEntryModalI18n';
import { redistributableStatusFilterI18n } from './redistributableStatusFilterI18n';
import { cancelRepairModalI18n } from './repairs/cancelRepairsModalI18n';
import { repairsPageTabI18n } from './repairs/repairsPageTabI18n';
import { revertRepairStatusModalI18n } from './repairs/revertRepairsModalI18n';
import { repaymentPlanDetailsTabI18n } from './repaymentPlanDetailsTabI18n';
import { revertContractEndModalI18n } from './revertContractEndModalI18n';
import { adminShipmentClassI18n } from './shipments/adminShipmentClassI18n';
import { collectionRequestDisplayStatusI18n } from './shipments/collectionRequestDisplayStatusI18n';
import { deEscalateCollectionSurveyModalI18n } from './shipments/deEscalateCollectionSurveyModalI18n';
import { deferCollectionSurveyI18n } from './shipments/deferCollectionSurveyI18n';
import { escalateCollectionSurveyModalI18n } from './shipments/escalateCollectionSurveyModalI18n';
import { undoDeferCollectionSurveyModalI18n } from './shipments/undoDeferCollectionSurveyI18n';
import { splitterTypeI18n } from './splitterTypeI18n';
import { supplierContactRoleI18n } from './supplierContactRoleI18n';
import { supplierStatusI18n } from './supplierStatusI18n';
import { supplierTabI18n } from './supplierTabI18n';
import { supplierTypeI18n } from './supplierTypeI18n';
import { syncTwoModalI18n } from './syncTwoModalI18n';
import { syncUserToZendeskModalI18n } from './syncUserToZendeskDataModalI18n';
import { taxStatusI18n } from './taxStatusI18n';
import { twoCreditDecisionI18n } from './twoCreditDecitionI18n';
import { twoSyncStatusI18n } from './twoSyncStatusI18n';
import { updateCollectionSurveyShipmentAddressModalI18n } from './updateCollectionSurveyShipmentAddressModalI18n';
import { updatePackagingTrackingDataModalI18n } from './updatePackagingTrackingDataModalI18n';
import { uploadTwoPayoutsModalI18n } from './uploadTwoPayoutsModalI18n';
import { uploadUsersI18nErrorCodes } from './uploadUsersI18nErrorCodes';
import { usersI18n } from './usersI18n';
import { userTabI18n } from './userTabI18n';

export const adminI18n = {
    'app.header.dropdown.sign-out': 'Sign out',
    'app.header.dropdown.go-to-hofy': 'Go to Hofy',
    'app.header.mode.admin': 'Admin mode',
    ...globalI18n,
    ...coreI18n,
    ...authI18n,
    ...sharedApiI18n,
    ...adminApiI18n,
    ...activityLogI18n,
    ...addressI18n,
    ...productI18n,

    ...accountKeyI18n,
    ...addLoanerModalI18n,
    ...adminAccountingI18n,
    ...adminAssignmentSlideoutTabI18n,
    ...adminBillingEntityPageI18n,
    ...adminBillingEntityTabI18n,
    ...adminContractFilterI18n,
    ...adminIntegrationErrorsFilterI18n,
    ...adminInventoryI18n,
    ...adminInvoicingEntityFilterI18n,
    ...adminInvoicingTabI18n,
    ...adminIssueFilterI18n,
    ...adminOrderFilterI18n,
    ...adminOrderSlideoutTabI18n,
    ...adminOrderSortOptionI18n,
    ...adminShipmentClassI18n,
    ...adminSettingsTabI18n,
    ...adminShipmentFilterI18n,
    ...adminShipmentPagei18n,
    ...adminShipmentSlideoutCollectionSurveyStatusI18n,
    ...adminShipmentSlideoutTabI18n,
    ...appendAssignmenttoShipmentModalI18n,
    ...assignmentConfigurationConfirmationModalI18n,
    ...assignmentConfigurationStatusI18n,
    ...assignmentDataErasureStatusI18n,
    ...assignmentItemDisposeI18n,
    ...assignmentPurchaseI18n,
    ...assignmentRevertCompletionModalI18n,
    ...assignmentShipmentCreateSurveyModalI18n,
    ...assignmentShipmentDetailsUpdateModalI18n,
    ...assignmentShipmentClearTrackingExceptionModalI18n,
    ...assignmentShipmentEnableTrackingModalI18n,
    ...assignmentShipmentIssueModalI18n,
    ...assignmentsPageTabI18n,
    ...assignmentStolenModalI18n,
    ...auditLogOperationTypeI18n,
    ...auditTableI18n,
    ...billingEntitiesTabI18n,
    ...billingEntityCurrencyUpdateI18n,
    ...cancelCollectionModalI18n,
    ...cancelOrderModalI18n,
    ...cancelRepairModalI18n,
    ...cancelStoreAndReuseCollectionModalI18n,
    ...cancelStoreAndReuseDeliveryModalI18n,
    ...cancelSubstituteProductModalI18n,
    ...cancelCustomProductModalI18n,
    ...collectAssignmentModalI18n,
    ...collectionRequestDisplayStatusI18n,
    ...contractsI18n,
    ...convertLoanerToReplacementModalI18n,
    ...creditContractModalI18n,
    ...creditInvoiceEntryModalI18n,
    ...dataErasureI18n,
    ...dataErasureStatusI18n,
    ...deEscalateCollectionSurveyModalI18n,
    ...deferCollectionSurveyI18n,
    ...deleteExchangeRateModalI18n,
    ...deleteFileModalI18n,
    ...deleteInvoiceEntryModalI18n,
    ...deleteOrganizationDiscountModalI18n,
    ...deleteTermAndConditionModalI18n,
    ...deleteUserPersonalDataModalI18n,
    ...disconnectOrganizationHRISModal,
    ...editShipmentSlideoutI18n,
    ...enableStoreAndReuseCollectionModalI18n,
    ...enableStoreAndReuseDeliveryI18n,
    ...escalateCollectionSurveyModalI18n,
    ...exportFieldI18n,
    ...fixedAssetStatusI18n,
    ...fixedAssetTypeI18n,
    ...hofyWarehouseTypeI18n,
    ...initialCostSource18n,
    ...invoiceDetailsTabI18n,
    ...invoiceEntryTypeI18n,
    ...invoicePaymentStatusI18n,
    ...itemConfirmSerialNumberChangeModalI18n,
    ...itemEventStatusI18n,
    ...itemFilterTypeI18n,
    ...itemGradeI18n,
    ...itemConditionI18n,
    ...itemLocationI18n,
    ...itemRemoveOrganizationModalI18n,
    ...itemSlideoutTabI18n,
    ...jobContextI18n,
    ...markCollectionRequestItemAsFoundI18n,
    ...netsuiteSyncStatusI18n,
    ...orderTypeI18n,
    ...organizationCurrencyUpdateI18n,
    ...organizationDataErasureUpdateI18n,
    ...organizationMdmUpdateI18n,
    ...organizationFeaturesUpdateI18n,
    ...organizationStatusUpdateI18n,
    ...organizationDetailsUpdateI18n,
    ...organizationFinancialSettingsUpdateI18n,
    ...organizationsI18n,
    ...organizationTabI18n,
    ...organizationTypeI18n,
    ...paymentDataStatusI18n,
    ...paymentMethodI18n,
    ...paymentProviderI18n,
    ...paymentStatusFilterI18n,
    ...payoutProviderI18n,
    ...platformTierI18n,
    ...productDetailsTabI18n,
    ...productStatusI18n,
    ...purchaseOrderStatusI18n,
    ...purchaseOrderTabI18n,
    ...recreateInvoiceEntryModalI18n,
    ...redistributableStatusFilterI18n,
    ...removeAssignmentFromShipmentModalI18n,
    ...repaymentPlanDetailsTabI18n,
    ...replaceAssignmentModalI18n,
    ...revertContractEndModalI18n,
    ...repairsPageTabI18n,
    ...revertRepairStatusModalI18n,
    ...splitterTypeI18n,
    ...supplierContactRoleI18n,
    ...supplierStatusI18n,
    ...supplierTabI18n,
    ...supplierTypeI18n,
    ...syncTwoModalI18n,
    ...syncUserToZendeskModalI18n,
    ...taxStatusI18n,
    ...twoSyncStatusI18n,
    ...undoDeferCollectionSurveyModalI18n,
    ...updateCollectAssignmentModalI18n,
    ...updateCollectionSurveyShipmentAddressModalI18n,
    ...updateOtdModalI18n,
    ...updateOtdReasonI18n,
    ...updatePackagingTrackingDataModalI18n,
    ...uploadTwoPayoutsModalI18n,
    ...usersI18n,
    ...userTabI18n,
    ...deleteRejectedPlansModalI18n,
    ...putAssignmentOnHoldModalI18n,
    ...removeOnHoldAssignmentModalI18n,
    ...uploadUsersI18nErrorCodes,
    ...twoCreditDecisionI18n,
    ...recheckPlansModalI18n,
    ...cancelPlanModalI18n,
    ...forceRecreatePlanModalI18n,
};

export type AdminI18nKey = keyof typeof adminI18n;
