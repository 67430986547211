import { ContractExpirySettingsPayload } from '@hofy/api-shared';
import { restClient } from '@hofy/rest';

import { InvoiceGroupsPayload } from './types/InvoiceGroupsPayload';
import { UpdateEndOfContractPayload } from './types/UpdateEndOfContractPayload';

class OrganizationService {
    public updateInvoiceGroups = async (organizationId: number, payload: InvoiceGroupsPayload) => {
        return restClient.post(`/api/admin/organizations/${organizationId}/invoice-groups`, payload);
    };

    public updateEndOfContract = async (organizationId: number, payload: UpdateEndOfContractPayload) => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/end-of-contract`, payload);
    };

    public updateContractExpirySettings = async (
        organizationId: number,
        payload: ContractExpirySettingsPayload,
    ) => {
        return restClient.putJson(
            `/api/admin/organizations/${organizationId}/contract-expiry-settings`,
            payload,
        );
    };
}

export const organizationService = new OrganizationService();
