import { useMutation, useQueryClient } from '@tanstack/react-query';

import { isRequired, useForm, useToast, validator } from '@hofy/ui';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import { OrganizationDetailsDto } from '../../services/organizations/types/OrganizationDto';
import { UpdateOrganizationDetailsPayload } from '../../services/organizations/types/UpdateOrganizationDetailsPayload';
import { organizationsCacheKey } from './organizationsCacheKey';

export const useAdminUpdateOrganizationDetails = (
    organization: OrganizationDetailsDto,
    onSuccess?: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();

    const mutation = useMutation({
        mutationFn: (payload: UpdateOrganizationDetailsPayload) =>
            adminOrganizationsService.updateOrganizationDetails(organization.id, payload),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [organizationsCacheKey, organization.id] });
            showToast({
                message: `Organization ${organization.name} details successfully updated`,
                type: 'positive',
            });
            onSuccess?.();
        },
    });

    const form = useForm<UpdateOrganizationDetailsPayload>({
        initial: {
            name: organization.name,
            size: organization.size,
            logoUrl: organization.logoUrl,
            salesAdminId: organization.salesAdminUser?.id ?? null,
            successAdminId: organization.successAdminUser?.id ?? null,
        },
        onSubmit: mutation.mutate,
        validate: validator<UpdateOrganizationDetailsPayload>({
            name: [isRequired('Organization name is required')],
        }),
    });

    return {
        form,
        isLoading: mutation.isPending,
    };
};
