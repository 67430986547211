import { getEnumValues } from '@hofy/global';

import { TermAndCondition } from './TermAndConditions';

export interface TermAndConditionRefDto {
    id: number;
    name: TermAndCondition;
}

export const getTermAndCondition = (ref: TermAndConditionRefDto): TermAndCondition | null => {
    return getEnumValues<TermAndCondition>(TermAndCondition).find(term => term === ref.name) ?? null;
};
