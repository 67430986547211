import { useMutation, useQueryClient } from '@tanstack/react-query';

import { contractsCacheKey, contractService, UpdateEndOfContractOptionPayload } from '@hofy/api-admin';
import { EndOfContractOption } from '@hofy/api-shared';
import { useForm, useToast } from '@hofy/ui';

interface EditContractEndOptionFormData {
    endOfContractOption: EndOfContractOption | null;
}

export const useUpdateEndOfContractOption = (
    contractId: number,
    contractEndOption: EndOfContractOption | null,
    onEnd: () => void,
) => {
    const { showToast } = useToast();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: (payload: UpdateEndOfContractOptionPayload) =>
            contractService.updateEndOfContractOption(contractId, payload),
        onSuccess: () => {
            showToast({
                type: 'positive',
                message: 'Contract end option updated',
            });
            onEnd();
            queryClient.invalidateQueries({ queryKey: [contractsCacheKey] });
        },
    });

    const form = useForm<EditContractEndOptionFormData>({
        initial: {
            endOfContractOption: contractEndOption,
        },
        onSubmit: mutation.mutate,
        onDiscard: onEnd,
    });

    return {
        form,
        isLoading: mutation.isPending,
        isError: mutation.isError,
    };
};
