import { useQuery } from '@tanstack/react-query';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { adminOrganizationsService } from '../../services/organizations/adminOrganizationsService';
import {
    AdminOrganizationsFilters,
    emptyAdminOrganizationsFilters,
} from '../../services/organizations/types/AdminOrganizationsFilters';
import { OrganizationDto } from '../../services/organizations/types/OrganizationDto';
import { organizationsCacheKey } from './organizationsCacheKey';

const emptyOrganizations: OrganizationDto[] = [];
export const useOrganizationsQuery = (filters: AdminOrganizationsFilters) => {
    const { data, isLoading, hasNextPage, isFetchingNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: [
            organizationsCacheKey,
            filters.search,
            filters.statuses,
            filters.currencies,
            filters.platformTiers,
            filters.accountManagers,
            filters.salesReps,
        ],

        queryFn: param =>
            adminOrganizationsService.listOrganizations(filters, {
                page: param.pageParam,
                pageSize: 50,
            }),
        initialPageParam: 0,
        getNextPageParam: lastPage => (lastPage.hasNext ? lastPage.page + 1 : undefined),
    });

    const organizations: OrganizationDto[] = useMemo(() => {
        if (data === undefined) {
            return [];
        }

        return data.pages.flatMap(v => v.content);
    }, [data]);

    return {
        organizations,
        isLoading,
        hasNextPage,
        isFetchingNextPage,
        fetchNextPage,
    };
};

export const useOrganizationsRefsQuery = (
    filters: AdminOrganizationsFilters = emptyAdminOrganizationsFilters,
) => {
    const { isLoading, data } = useQuery({
        queryKey: [
            organizationsCacheKey,
            filters.search,
            filters.statuses,
            filters.currencies,
            filters.platformTiers,
            filters.accountManagers,
            filters.salesReps,
        ],
        queryFn: () => adminOrganizationsService.getOrganizationRefs(filters),
    });
    return {
        isLoading,
        data: data || emptyOrganizations,
    };
};
