import { Duration } from 'luxon';

import {
    ContractType,
    EndOfContractOption,
    OrganizationRefDto,
    PaymentSchema,
    ProductRefDto,
    PurchaseContractStatus,
    RentalContractStatus,
    RentalContractType,
    RentalTerm,
    StoreAndReuseDto,
    VariantDto,
} from '@hofy/api-shared';
import { DateString, Price, UUID } from '@hofy/global';
import { isDateInPast, nowDate, parseDateTime } from '@hofy/helpers';

export type ContractUnionDto = ContractDtoWithRental | ContractDtoWithPurchase | ContractDtoWithManagement;

interface ContractDto {
    id: number;
    uuid: UUID;
    publicId: string;
    type: ContractType;
    organization: OrganizationRefDto;
    variant: VariantDto;
    product: ProductRefDto;
    rentalContract: RentalContractDto | null;
    purchaseContract: PurchaseContractDto | null;
}

interface ContractDtoWithRental extends ContractDto {
    type: ContractType.Rental;
    rentalContract: RentalContractDto;
    purchaseContract: null;
}

interface ContractDtoWithPurchase extends ContractDto {
    type: ContractType.Purchase;
    purchaseContract: PurchaseContractDto;
    rentalContract: null;
}

interface ContractDtoWithManagement extends ContractDto {
    type: ContractType.Management;
}

interface RentalContractDto {
    status: RentalContractStatus;
    rentalType: RentalContractType;
    rentalTerm: RentalTerm;
    rentalLength: number;
    paymentSchema: PaymentSchema;

    basePrice: Price;
    unitPrice: Price;
    price: Price;

    pendingOn: DateString;
    activeOn: DateString | null;
    rolloverOn: DateString | null;
    endedOn: DateString | null;
    cancelledOn: DateString | null;
    possibleRedistribution: boolean;
    storeAndReuse: StoreAndReuseDto | null;
    isRedistributableOrReusable: boolean;
    endOfContractOption: EndOfContractOption | null;
    estimatedEndOfContract: string | null;
}

interface PurchaseContractDto {
    status: PurchaseContractStatus;
    pendingOn: DateString;
    cancelledOn: DateString | null;
    purchasedOn: DateString | null;

    basePrice: Price;
    unitPrice: Price;
    price: Price;
}

export const contractRemainingMonths = (contract: ContractUnionDto) => {
    if (contract.type !== ContractType.Rental) {
        return undefined;
    }

    if (!contract.rentalContract.activeOn || !contract.rentalContract.rentalLength) {
        return undefined;
    }

    const activeOn = parseDateTime(contract.rentalContract.activeOn);
    const duration = Duration.fromObject({
        months: contract.rentalContract.rentalLength,
    });
    const endsAt = activeOn.plus(duration);

    if (isDateInPast(endsAt)) {
        return 0;
    }

    const difference = endsAt.diff(nowDate(), 'months');
    return Math.floor(difference.months);
};
export const contractPrice = (contract: ContractUnionDto) => {
    switch (contract.type) {
        case ContractType.Rental:
            return contract.rentalContract.price;
        case ContractType.Purchase:
            return contract.purchaseContract.price;
        case ContractType.Management:
            return null;
    }
    return null;
};
export const contractBasePrice = (contract: ContractUnionDto) => {
    switch (contract.type) {
        case ContractType.Rental:
            return contract.rentalContract.basePrice;
        case ContractType.Purchase:
            return contract.purchaseContract.basePrice;
        case ContractType.Management:
            return null;
    }
    return null;
};
export const contractPaymentSchema = (contract: ContractUnionDto) => {
    switch (contract.type) {
        case ContractType.Rental:
            return contract.rentalContract.paymentSchema;
        case ContractType.Purchase:
            return PaymentSchema.Upfront;
        case ContractType.Management:
            return null;
    }
    return null;
};
