import { StringifiableRecord } from 'query-string';

import { CategoryContractSettingsConfigDto, OrganizationRefDto, OrganizationType } from '@hofy/api-shared';
import { PageRequest, PageResponse, stringifyUrl } from '@hofy/global';
import { downloadFileFromResponse } from '@hofy/helpers';
import { restClient } from '@hofy/rest';

import { AdminAddOrganizationTermAndConditionFormData } from '../../store/organizations/types/AdminAddOrganizationTermAndConditionFormData';
import { AdminOrganizationsFilters, emptyAdminOrganizationsFilters } from './types/AdminOrganizationsFilters';
import {
    CreateOrganizationFormData,
    CreateOrganizationPayload,
    OrganizationManager,
} from './types/CreateOrganizationPayload';
import { DisconnectOrganizationHRISFormData } from './types/DisconnectOrganizationHRISFormData';
import { OrganizationDiscountFormData } from './types/OrganizationDiscountFormData';
import {
    OrganizationDetailsDto,
    OrganizationDto,
    OrganizationTermAndConditionDto,
} from './types/OrganizationDto';
import { UpdateOrganizationCurrencyPayload } from './types/UpdateOrganizationCurrencyPayload';
import { UpdateOrganizationDataErasureSettingsPayload } from './types/UpdateOrganizationDataErasureSettingsPayload';
import { UpdateOrganizationDetailsPayload } from './types/UpdateOrganizationDetailsPayload';
import { UpdateOrganizationFeaturesPayload } from './types/UpdateOrganizationFeaturesPayload';
import { UpdateOrganizationFinancialSettingsPayload } from './types/UpdateOrganizationFinancialSettingsPayload';
import { UpdateOrganizationMdmPayload } from './types/UpdateOrganizationMdmPayload';
import { UpdateOrganizationPaymentDiscountsFormData } from './types/UpdateOrganizationPaymentDiscountsFormData';
import { UpdateOrganizationStatusPayload } from './types/UpdateOrganizationStatusPayload';

interface OrganizationTermAndConditionsResponse {
    organizationTermAndConditions: OrganizationTermAndConditionDto[];
}

class AdminOrganizationsService {
    public listOrganizations = async (
        filters: AdminOrganizationsFilters,
        page: PageRequest,
    ): Promise<PageResponse<OrganizationDto>> => {
        return await restClient.getJson<PageResponse<OrganizationDto>>(
            stringifyUrl({
                url: '/api/admin/organizations',
                query: {
                    ...filters,
                    ...page,
                },
            }),
        );
    };

    public downloadListOrganizationsReport = (): Promise<void> => {
        return restClient.downloadFile('/api/admin/organizations/report').then(downloadFileFromResponse);
    };

    public getOrganizationRefs = async (
        filters: AdminOrganizationsFilters = emptyAdminOrganizationsFilters,
    ): Promise<OrganizationRefDto[]> => {
        return await restClient.getJson<OrganizationRefDto[]>(
            stringifyUrl({
                url: '/api/admin/organizations/refs',
                query: filters as any as StringifiableRecord,
            }),
        );
    };

    public getOrganization = (id: number): Promise<OrganizationDetailsDto> => {
        return restClient.getJson<OrganizationDetailsDto>(`/api/admin/organizations/${id}`);
    };

    public deleteOrganization = (id: number): Promise<void> => {
        return restClient.delete(`/api/admin/organizations/${id}`).then();
    };

    public createOrganization = async (formData: CreateOrganizationFormData): Promise<number> => {
        return restClient
            .postJson<{ id: number }>('/api/admin/organizations', this.createOrganizationToPayload(formData))
            .then(r => r.id);
    };

    public disconnectOrganizationHRIS = async (id: number, payload: DisconnectOrganizationHRISFormData) => {
        return restClient.post(`/api/admin/organizations/${id}/disconnect-hris`, payload);
    };

    private createOrganizationToPayload = (
        formData: CreateOrganizationFormData,
    ): CreateOrganizationPayload => {
        const isStandard = formData.organizationType === OrganizationType.Standard;
        const isDemo = formData.organizationType === OrganizationType.Demo;

        const seedProducts = isStandard ? formData.seedProducts : false;
        const seedProductsWithFurniture = isStandard ? formData.seedWithFurniture : false;

        const organizationType = isDemo ? OrganizationType.Standard : formData.organizationType;

        return {
            name: formData.name,
            currency: formData.currency,
            organizationType: organizationType,
            platformTier: formData.platformTier,
            seedProducts: seedProducts,
            seedProductsWithFurniture: seedProductsWithFurniture,
            manager: this.createOrganizationManagerPayload(formData),
            successAdminId: formData.successAdminId,
            salesAdminId: formData.salesAdminId,
        };
    };

    private createOrganizationManagerPayload = (
        formData: CreateOrganizationFormData,
    ): OrganizationManager | null => {
        if (!formData.createManager) {
            return null;
        }
        return {
            firstName: formData.managerFirstName,
            lastName: formData.managerLastName,
            email: formData.managerEmail,
            country: formData.managerCountry,
            sendInvite: formData.managerSendInvite,
        };
    };

    public updateOrganizationFinancialSettings = async (
        organizationId: number,
        payload: UpdateOrganizationFinancialSettingsPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/financial-settings`, payload);
    };

    public updateOrganizationFeatures = async (
        organizationId: number,
        payload: UpdateOrganizationFeaturesPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/features`, payload);
    };

    public updateOrganizationMdm = async (
        organizationId: number,
        payload: UpdateOrganizationMdmPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/mdm`, payload);
    };

    public updateOrganizationStatus = async (
        organizationId: number,
        payload: UpdateOrganizationStatusPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/status`, payload);
    };

    public updateOrganizationDetails = async (
        organizationId: number,
        payload: UpdateOrganizationDetailsPayload,
    ): Promise<number> => {
        return restClient.putJson(`/api/admin/organizations/${organizationId}/details`, payload);
    };

    public updateOrganizationDataErasureSettings = async ({
        organizationId,
        enabled,
        fee,
    }: UpdateOrganizationDataErasureSettingsPayload): Promise<number> => {
        const payload = {
            enabled: enabled,
            fee: fee,
        };
        return restClient.putJson(
            `/api/admin/organizations/${organizationId}/data-erasure-settings`,
            payload,
        );
    };

    public updateOrganizationCurrency = async (
        organizationId: number,
        payload: UpdateOrganizationCurrencyPayload,
    ): Promise<void> => {
        return restClient.patchJson(`/api/admin/organizations/${organizationId}/currency`, payload);
    };

    public saveOrganizationDiscount = async (
        organizationId: number,
        { ...payload }: OrganizationDiscountFormData,
    ) => {
        if (payload.id) {
            return this.updateOrganizationDiscount(organizationId, payload);
        } else {
            return this.createOrganizationDiscount(organizationId, payload);
        }
    };

    public createOrganizationDiscount = async (
        organizationId: number,
        { ...payload }: OrganizationDiscountFormData,
    ) => {
        return restClient.post(`/api/admin/organizations/${organizationId}/discounts`, {
            ...payload,
            validStartOn: payload.validStartOn,
            validEndOn: payload.validEndOn,
        });
    };

    public updateOrganizationDiscount = async (
        organizationId: number,
        { id, ...payload }: OrganizationDiscountFormData,
    ) => {
        return restClient.put(`/api/admin/organizations/${organizationId}/discounts/${id}`, {
            ...payload,
            validStartOn: payload.validStartOn,
            validEndOn: payload.validEndOn,
        });
    };

    public deleteOrganizationDiscount = async (id: number) => {
        return restClient.delete(`/api/admin/organizations/discounts/${id}`);
    };

    public deleteOrganizationTermAndCondition = async (id: number) => {
        return restClient.delete(`/api/admin/organizations/term-and-condition/${id}`);
    };

    public updateOrganizationContractSettings = async (
        id: number,
        contractSettings: CategoryContractSettingsConfigDto,
    ) => {
        return restClient.post(`/api/admin/organizations/${id}/contract-settings`, contractSettings);
    };

    public accountingSync = async (organizationId: number) => {
        return restClient.post(`/api/admin/organizations/${organizationId}/accounting-sync`);
    };

    public syncToZendesk = async (organizationId: number) => {
        return restClient.post(
            stringifyUrl({
                url: `/api/admin/organizations/sync-zendesk`,
                query: {
                    organizationId,
                },
            }),
        );
    };

    public updateOrganizationPaymentDiscounts = async (
        formData: UpdateOrganizationPaymentDiscountsFormData,
    ) => {
        return restClient.put(`/api/admin/organizations/${formData.organizationId}/payment-discounts`, {
            paymentDiscounts: formData.paymentDiscounts,
        });
    };

    public listOrganizationTermAndConditions = async (
        orgId: number,
    ): Promise<OrganizationTermAndConditionDto[]> => {
        const result = await restClient.getJson<OrganizationTermAndConditionsResponse>(
            `/api/admin/organizations/${orgId}/terms-and-conditions`,
        );
        return result.organizationTermAndConditions;
    };

    public addOrganizationTermAndCondition = async ({
        organizationId,
        termAndCondition,
        ...payload
    }: AdminAddOrganizationTermAndConditionFormData) => {
        return restClient.post(
            `/api/admin/organizations/${organizationId}/term-and-condition/${termAndCondition.id}`,
            payload,
        );
    };
}

export const adminOrganizationsService = new AdminOrganizationsService();
