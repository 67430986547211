import { getEnumValues } from '@hofy/global';

import { ParentProductCategoryGroup } from './ProductCategory';

export enum ContractEndOption {
    Default = 'default',
    Purchase = 'purchase',
    Return = 'return',
    Refresh = 'refresh',
}

export const allContractEndOptions = getEnumValues<ContractEndOption>(ContractEndOption);
export const allDevicesContractEndOptions: ContractEndOption[] = [
    ContractEndOption.Purchase,
    ContractEndOption.Return,
    ContractEndOption.Refresh,
];

export const allAccessoriesContractEndOptions: ContractEndOption[] = [
    ContractEndOption.Purchase,
    ContractEndOption.Return,
];

export const contractEndOptionsForCategoryGroupMap: Record<ParentProductCategoryGroup, ContractEndOption[]> =
    {
        [ParentProductCategoryGroup.Devices]: allDevicesContractEndOptions,
        [ParentProductCategoryGroup.Accessories]: allAccessoriesContractEndOptions,
    };
