import React, { FC } from 'react';

import { allOrganizationSizes, Role, useTrOrganizationSize } from '@hofy/api-shared';
import { zeroUuid } from '@hofy/global';
import {
    AsyncButton,
    FormContainer,
    FormInput,
    FormSection,
    FormSelect,
    Modal,
    ModalContent,
    ModalFooter,
    ModalHeader,
} from '@hofy/ui';

import { CancelButton } from '../../../components/design/button/CancelButton';
import { ImageEditor } from '../../../components/design/imageEditor/ImageEditor';
import { Image } from '../../../components/design/imageEditor/types/Image';
import { useAdminI18n } from '../../../i18n/useAdminI18n';
import { OrganizationDetailsDto } from '../../../services/organizations/types/OrganizationDto';
import { useAdminUpdateOrganizationDetails } from '../../../store/organizations/useAdminUpdateOrganizationDetails';
import { OrganizationUsersListByRoleDropdown } from './OrganizationUsersListByRoleDropdown';

interface DetailsUpdateModalProps {
    organization: OrganizationDetailsDto;
    onClose(): void;
}

export const DetailsUpdateModal: FC<DetailsUpdateModalProps> = ({ organization, onClose }) => {
    const { form, isLoading } = useAdminUpdateOrganizationDetails(organization, onClose);
    const { tr } = useAdminI18n();

    const trOrganizationSize = useTrOrganizationSize();

    const setLogo = (images: Image[]) => {
        const activeImages = images.filter(({ isActive }) => !!isActive);
        if (!activeImages.length) {
            form.setValues({
                logoUrl: null,
            });
        } else {
            form.setValues({
                logoUrl: activeImages[activeImages.length - 1].url,
            });
        }
    };

    return (
        <Modal onClose={onClose} width={450}>
            <ModalHeader
                title={tr('organization-page.details-update.title')}
                subtitle={tr('organization-page.details-update.subtitle')}
            />
            <ModalContent>
                <FormContainer>
                    <FormInput
                        label={tr('organization-page.details-update.modal.organization-name')}
                        api={form.fields.name}
                        isRequired
                    />
                    <FormSelect
                        label={tr('organization-page.details-update.modal.organization-size')}
                        api={form.fields.size}
                        options={allOrganizationSizes}
                        toText={trOrganizationSize}
                    />
                    <FormSection label={tr('organization-page.details-update.modal.logo-section')}>
                        <ImageEditor
                            images={
                                form.values.logoUrl
                                    ? [
                                          {
                                              url: form.values.logoUrl,
                                              isActive: true,
                                              uuid: zeroUuid,
                                          },
                                      ]
                                    : []
                            }
                            onChange={setLogo}
                            showDeleted
                        />
                    </FormSection>
                    <FormSection label={tr('organization-page.details-update.modal.sales-section')}>
                        <OrganizationUsersListByRoleDropdown
                            role={Role.SalesAdmin}
                            label={tr('organization-page.details-update.modal.sales-representative')}
                            value={form.values.salesAdminId}
                            onChange={id =>
                                form.setValues({
                                    salesAdminId: id,
                                })
                            }
                            isError={form.errors.salesAdminId}
                        />

                        <OrganizationUsersListByRoleDropdown
                            role={Role.SuccessAdmin}
                            label={tr('organization-page.details-update.modal.account-manager')}
                            value={form.values.successAdminId}
                            onChange={id =>
                                form.setValues({
                                    successAdminId: id,
                                })
                            }
                            isError={form.errors.successAdminId}
                        />
                    </FormSection>
                </FormContainer>
            </ModalContent>
            <ModalFooter>
                <CancelButton label={tr('organization-page.details-update.modal.cancel')} onClick={onClose} />
                <AsyncButton
                    label={tr('organization-page.details-update.modal.confirm')}
                    disableCheck
                    onClick={form.submit}
                    isLoading={isLoading}
                />
            </ModalFooter>
        </Modal>
    );
};
